<template>
  <div class="mainform">
    <div class="form">
      <el-form  class="content bascform" ref="addpayplan" :model="form"  label-width="100px">
        <div class="col col4">
          <el-form-item label="用户账号" prop="userAccount" >
            <el-input v-model="form.userAccount" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="用户昵称" prop="username" >
            <el-input v-model="form.username" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="部门" prop="department" >
            <el-select v-model="form.department" placeholder="">
              <el-option label="采购部" value="采购部"></el-option>
              <el-option label="产品部" value="产品部"></el-option>
              <el-option label="品管部" value="品管部"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
// import { requestForm } from '@/assets/js/http.js'

export default {
  name: 'AddSupAllDialog',
  components: { ...Component },
  data: function () {
    return {
      form: {},
      supplierOptions: [],
      supplierLoading: false,
      dialogShow: false,
      bankShow: false,
      companyCode: ''
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.mainform{
  .form{
    padding: 0;
  }
}
</style>
